



























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { NavigationMenuItemInterface } from '@/interfaces/NavigationMenuItem.interface';
import { ROUTE_STORE } from '@/router/routes';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import Dealer from '@/models/Dealer.model';
import { namespace } from 'vuex-class';
import { AuthStoreActions, AuthStoreMutations } from '@/store/auth.store';
import { DealerStoreGetters } from '@/store/dealer.store';
import Store from '@/models/Store.model';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const AuthStore = namespace('auth');
const DealerStore = namespace('dealer');

@Component({
  components: {
    EditDealerComponent: () => import(
      /* webpackChunkName: "EditDealerComponent" */
      '@/components/EditDealer.component.vue'
    ),
  }
})
export default class NavigationDrawerComponent extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  private navigationMenuItems!: NavigationMenuItemInterface[];

  @Prop({ required: true })
  private onLogout!: () => void;

  @AuthStore.Action(AuthStoreActions.INITIATE_PASSWORD_RESET)
  private initiatePasswordResetAction!: (email: string) => Promise<any>;

  @DealerStore.Getter(DealerStoreGetters.CURRENT_DEALER)
  private updatedDealer?: Dealer;

  @AuthStore.Mutation(AuthStoreMutations.SET_USER)
  private setCurrentUser!: (dealer: Dealer) => void;

  private showNavigationDrawer: boolean = false;
  private showUserEditDialog: boolean = false;

  get nonAdminItems(): NavigationMenuItemInterface[] {
    let items = this.navigationMenuItems.filter(item => !item.adminOnly);
    if (!this.currentUser.isStoreAdmin || !this.currentUser.isAdmin) {
      items = items.filter(item => !item.storeAdminOrAdminOnly);
    }
    return items;
  }

  get adminItems(): NavigationMenuItemInterface[] {
    return this.navigationMenuItems.filter(item => item.adminOnly);
  }

  private onNavItemClick(item: NavigationMenuItemInterface) {
    if (this.currentStore) {
      this.$router.push({ name: item.route, params: { id: this.currentStore.id! } });
    } else {
      this.$router.push({ name: item.route });
    }
  }

  private isStoreNavItem(item: NavigationMenuItemInterface): boolean {
    return item.route == ROUTE_STORE;
  }

  /**
   * Checks if current store is set. 
   * If not, navitem for store is not shown. 
   */
  private isValid(item: NavigationMenuItemInterface): boolean {
    return item.route !== ROUTE_STORE || this.currentStore !== undefined;
  }

  private async closeDealerDialog(reload: boolean) {
    if (reload) {
      // Get updated dealer from dealer store and update the current user:
      this.setCurrentUser(this.updatedDealer!);
    }
    this.showUserEditDialog = false;
  }

  private async startChangingPassword() {
    try {
      await this.initiatePasswordResetAction(this.currentUser.email!);
      this.$notifyInfoSimplified('GENERAL.NOTIFICATIONS.PASSWORD_RESET_MAIL_SENT');
    } catch (e) {
      this.handleAxiosError(e);
    }
  }

  get currentDealer(): Dealer {
    return Dealer.parseFromObject(this.currentUser);
  }
}
